<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">动物请购</h3>
    <div class="detail">
      <h4 class="title">采购实验动物信息</h4>
      <div class="desc">
        <span>动物品系：{{ activeInfo.strain }}</span>
        <span>清洁等级：{{
            activeInfo.grade == 0 ? '普通' : activeInfo.grade == 1 ? 'SPF' : '清洁级'
          }}</span>
      </div>
      <el-table border
                :data="activeInfo.aniOrderDetailList"
                class="record-table"
                :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
                max-height="400px">
        <el-table-column label="基因类型" v-if="activeInfo.isGene==1">
          <el-table-column :label="item.title" v-for="(item,ind) in typeList" :key="ind">
            <template slot-scope="scope">
              {{ scope.row.typeList[ind].type }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="性别">
          <template slot-scope="scope">
            {{ scope.row.gender == 0 ? '♀' : '♂' }}
          </template>
        </el-table-column>
        <el-table-column label="数量" show-overflow-tooltip prop="count">
        </el-table-column>
        <el-table-column label="体重(g)" prop="weight">
        </el-table-column>
        <el-table-column label="周龄" prop="weekDays">
        </el-table-column>
      </el-table>
      <h4 class="title" style="margin-top: 16px">采购方式</h4>
      <template v-if="status<6">
        <el-form ref="addForm" size="small" :model="purchaseWay" class="addForm " label-suffix=":"
                 label-width="80px">
          <el-form-item label="采购方式">
            <el-radio-group v-model="purchaseWay.buyWay" class="radioGroup" @change="change">
              <el-radio :label="1">指定采购员</el-radio>
              <el-radio :label="2">指定角色</el-radio>
              <el-radio :label="3">指定部门</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="采购员">
            <template v-if="purchaseWay.buyWay==1">
              <el-radio-group v-model="purchaseWay.user" class="radioGroup">
                <el-radio :label="ind" v-for="(item,ind) in userList" :key="item.userId"
                          @change="getAppId(item.userId,item.nickname)">
                  {{ item.nickname }}
                </el-radio>
              </el-radio-group>
            </template>
            <template v-else-if="purchaseWay.buyWay==2">
              <el-select v-model="purchaseWay.user" value-key="id" @change="getId" placeholder="请选择人员">
                <el-option v-for="item in roleList" :key="item.roleId" :label="item.name"
                           :value="item"></el-option>
              </el-select>
            </template>
            <template v-else>
              <a-tree-select
                  class="smallWidth" :dropdownStyle="{ maxHeight: '180px', overflow: 'auto' }"
                  :tree-data="partList" @select="getPartId"
                  placeholder='请选择部门' treeDefaultExpandAll v-model="purchaseWay.user">
              </a-tree-select>
            </template>
          </el-form-item>
          <div class="fromSave">
            <el-button type="primary" size="small" @click="purchaseBtn" :disabled="disabled">确认请购</el-button>
          </div>
        </el-form>
      </template>
      <template v-else>
        <div class="desc" v-if="activeInfo.aniBuy">
          <p>采购方式：{{ activeInfo.aniBuy.buyWay == 1 ? '指定采购员' : activeInfo.aniBuy.buyWay == 2 ? '指定角色' : '指定部门' }}</p>
          <p>采购员：
            <template v-if="activeInfo.aniBuy.buyWay == 1">{{ activeInfo.aniBuy.personName }}</template>
            <template v-else-if="activeInfo.aniBuy.buyWay == 2">{{ activeInfo.aniBuy.roleName }}</template>
            <template v-if="activeInfo.aniBuy.buyWay == 3">{{ activeInfo.aniBuy.deptName }}</template>
          </p>
        </div>
      </template>
    </div>
  </div>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: "taskPurchase",
  props: ['id', 'status'],
  data() {
    return {
      activeInfo: {},
      userList: [],
      roleList: [],
      partList: [],
      typeList: [],
      purchaseWay: {
        buyWay: 1,
        user: null,
        apprId: null,
        name: '',
      },
      disabled: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.account.user
    })
  },
  mounted() {
    this.getType()
    this.getUser()
    if (this.status >= 6) {
      this.disabled = true
    }
  },
  methods: {
    getType() {
      let that = this;
      that.$get("/subject/order/to/" + this.id).then((res) => {
        if (res.status == 200) {
          that.activeInfo = res.data
          // 表格内的基因类型
          res.data.aniOrderDetailList.forEach(obj => {
            obj.typeList = JSON.parse(obj.gene)
          })

          // 赋值typeList
          let arr = this.activeInfo.geneKeys.split(',')
          arr.forEach(item => {
            this.typeList.push({
              title: item
            })
          })
        }
      }).catch(() => {
        this.$message.error('获取数据失败')
      })
    },
    change(val) {
      this.purchaseWay.user = null
      if (val == 1) {
        this.getUser()
      } else if (val == 2) {
        // 角色
        this.$get("/oauth/role/" + this.user.companyId).then(res => {
          this.roleList = res.data
        })
      } else {
        // 部门
        this.$get("/oauth/dept/tree").then(res => {
          this.partList = res.data.children;
        })
      }
    },
    getUser() {
      // 指定采购员
      this.$get("/oauth/user/personal/" + this.user.companyId).then(res => {
        if (res.status == 200) {
          this.userList = res.data
        }
      })
    },
    // 指定采购员
    getAppId(id, name) {
      this.purchaseWay.apprId = id
      this.purchaseWay.name = name
    },
    // 获取角色
    getId(val) {
      this.purchaseWay.apprId = val.roleId
      this.purchaseWay.name = val.name
    },
    //指定部门
    getPartId(node, instance) {
      this.purchaseWay.apprId = node
      this.purchaseWay.name = instance.title
    },
    purchaseBtn() {
      let params = {
        buyWay: this.purchaseWay.buyWay,
        subId: this.activeInfo.subId
      }
      if (this.purchaseWay.buyWay == 1) {
        params.personId = this.purchaseWay.apprId
        params.personName = this.purchaseWay.name
      } else if (this.purchaseWay.buyWay == 2) {
        params.roleId = this.purchaseWay.apprId
        params.roleName = this.purchaseWay.name
      } else {
        params.deptId = this.purchaseWay.apprId
        params.deptName = this.purchaseWay.name
      }
      this.$postJson('/subject/ani-buy', params).then(res => {
        this.$message.success("请购成功")
        this.disabled = true
        this.$router.go(-1)
      }).catch(err => {
        this.$message.error("请购失败")
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";

.addForm {
  margin-top: 16px;

  .el-form-item:nth-child(2) {
    margin-bottom: 10px;
  }
}
</style>
